
import { Options, Vue } from 'vue-class-component';
import { ExpertClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class expertsList extends Vue {

    items: OM.ExpertForListVM[] = [];

    created(){
        ExpertClient.getAll()
        .then(x => {
            this.items = x;
        })
    }

}
